



















































































// @media (min-width: 1620px) {
//   .container {
//     max-width: 1620px;
//   }
// }
.jikan {
  box-sizing: border-box;
  overflow: hidden;
  background: #fff;
  height: 400px;
  box-shadow: 11px 20px 57px 0 rgba(0, 0, 0, 0.1);
  background: url(../assets/images/quarterly.png) no-repeat center;
  background-size: 100% 100%;
  display: block;
  max-width: 300px;
  padding: 0;
  border-radius: 8px;
  margin: 10px auto;
  transition: 1s !important;
  &::before {
    content: attr(data-title);
    position: absolute;
    left: 50%;
    top: 168px;
    font-size: 10px;
    transform: translateX(-50%);
  }
  &:hover {
    transform: scale(1.05) !important;
    box-shadow: 11px 20px 57px 0 rgba(0, 0, 0, 0.3);
    z-index: 2;
  }
}
.caseVue main {
  margin: 0;
  background: url("../assets/jikan/jikan.png") center center no-repeat;
  h1 {
    font-size: 30px;
    color: #000;
    font-weight: bold;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
}
.header {
  background: linear-gradient(90deg, #2d5fcd 0, #7b5ab6 100%), #2839a0;
  button {
    border-radius: 2rem;
    margin-top: 3rem;
    padding: 14px 33px;
    text-transform: uppercase;
    font-size: 16px;
    transition: 1s;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    background-color: transparent;
    border-color: #fff;
    color: #fff;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #409eff;
    }
  }
}
.caseItem {
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  padding: 1.5rem !important;
  // margin: 0;
  margin-bottom: 20px;
  min-height: 460px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  & > * {
    margin-bottom: 10px;
  }
  .title {
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
  }
  .content {
    text-indent: 2rem;
    text-align: justify;
  }
  & > img {
    width: calc(100% - 3rem);
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;
    right: 1.5rem;
    transition: 0.5s all linear;
    &:hover {
      transform: scale(1.3) rotate(4deg);
      // transition: ;
    }
  }
}
.ibanner_box {
  text-align: center;
  color: #000;
  font-size: 3rem;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  p {
    font-size: 30px;
  }
}
